import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () => import('app/Root').then(({ Root }) => Root),
  errorBoundary() {
    return <></>
  },
})

export const { bootstrap, mount, unmount } = lifecycles
